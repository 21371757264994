import React from "react";
import { DollarSign, FileText, Film, Gift, Settings, Tag, Triangle, User, Users, Wind } from "react-feather";
import { RouteComponentProps } from "react-router";
import { Link, withRouter } from "react-router-dom";
import { AvailableRoutesEnum } from "../config/permissions";
import { withPermission } from "../hoc/withPermission";

// Type whatever you expect in 'this.props.match.params.*'
interface PathParamsType {
    pathname: string;
}

// Your component own properties
type PropsType = RouteComponentProps<PathParamsType> & {
    location: {
        pathname: string;
    };
};

enum STRINGS {
    TASK_RUNNER = "Task Runner",
    COMP_ACCOUNTS = "Add Comp Account",
    CONTRACTS = "Contracts",
    CSR_TOOL = "CSR Tool",
    ACCOUNT_CLEANUP = "Account Cleanup",
    PLANS = "Plans",
    GIFT_VOUCHERS = "Gift Vouchers",
    GIFTS = "Gifts Config",
    USERS = "User Management",
    PROGRAMS = "Programs",
    LOGS = "System Logs",
}

const Li = ({ ...props }) => <li {...props} />;
const GuardTaskRunnerRoute = withPermission(Li, AvailableRoutesEnum.TASK_RUNNER);
const GuardAccountCleanUpRoute = withPermission(Li, AvailableRoutesEnum.ACCOUNT_CLEANUP);
const GuardCompAccountRoute = withPermission(Li, AvailableRoutesEnum.COMP_ACCOUNTS);
const GuardCsrRoute = withPermission(Li, AvailableRoutesEnum.CSR_TOOL);
const GuardGiftRoute = withPermission(Li, AvailableRoutesEnum.GIFTS_VOUCHER);
const GuardGiftsRoute = withPermission(Li, AvailableRoutesEnum.GIFTS);
const GuardPlansRoute = withPermission(Li, AvailableRoutesEnum.PLANS);
const GuardProgramRoute = withPermission(Li, AvailableRoutesEnum.PROGRAMS);
const GuardUserManagementRoute = withPermission(Li, AvailableRoutesEnum.USER_MANAGEMENT);
const GuardLogsRoute = withPermission(Li, AvailableRoutesEnum.LOGS);

class Sidebar extends React.PureComponent<PropsType> {
    public render(): JSX.Element {
        const { pathname } = this.props.location;
        const navItemClass = "nav-item";
        const navLinkClass = "nav-link";
        const navLinkActiveClass = `${navLinkClass} active`;

        return (
            <nav className="col-md-2 d-none d-md-block bg-light sidebar">
                <div className="sidebar-sticky">
                    <ul className="nav flex-column">
                        <GuardTaskRunnerRoute className={navItemClass}>
                            <Link to={"/task-runner"} className={pathname === "/task-runner" ? navLinkActiveClass : navLinkClass}>
                                <Triangle size={17} /> {STRINGS.TASK_RUNNER}
                            </Link>
                        </GuardTaskRunnerRoute>
                        <div className="sidebar-hr">
                            <hr className="my-2" />
                        </div>
                        <GuardAccountCleanUpRoute className={navItemClass}>
                            <Link to={"/account-cleanup"} className={pathname === "/account-cleanup" ? navLinkActiveClass : navLinkClass}>
                                <Wind size={17} /> {STRINGS.ACCOUNT_CLEANUP}
                            </Link>
                        </GuardAccountCleanUpRoute>

                        <GuardCompAccountRoute className={navItemClass}>
                            <Link to={"/comp-accounts"} className={pathname === "/comp-accounts" ? navLinkActiveClass : navLinkClass}>
                                <Users size={17} /> {STRINGS.COMP_ACCOUNTS}
                            </Link>
                        </GuardCompAccountRoute>

                        <GuardCsrRoute className={navItemClass}>
                            <Link to={"/csr-tool"} className={pathname === "/csr-tool" ? navLinkActiveClass : navLinkClass}>
                                <Settings size={17} /> {STRINGS.CSR_TOOL}
                            </Link>
                        </GuardCsrRoute>

                        <GuardGiftRoute className={navItemClass}>
                            <Link to={"/gift-vouchers"} className={pathname === "/gift-vouchers" ? navLinkActiveClass : navLinkClass}>
                                <Gift size={17} /> {STRINGS.GIFT_VOUCHERS}
                            </Link>
                        </GuardGiftRoute>

                        <GuardPlansRoute className={navItemClass}>
                            <Link to={"/plans"} className={pathname === "/plans" ? navLinkActiveClass : navLinkClass}>
                                <DollarSign size={17} /> {STRINGS.PLANS}
                            </Link>
                        </GuardPlansRoute>

                        <GuardGiftsRoute className={navItemClass}>
                            <Link to={"/gifts"} className={pathname === "/gifts" ? navLinkActiveClass : navLinkClass}>
                                <Tag size={17} /> {STRINGS.GIFTS}
                            </Link>
                        </GuardGiftsRoute>

                        <GuardProgramRoute className={navItemClass}>
                            <Link to={"/programs"} className={pathname === "/programs" ? navLinkActiveClass : navLinkClass}>
                                <Film size={17} /> {STRINGS.PROGRAMS}
                            </Link>
                        </GuardProgramRoute>

                        <GuardUserManagementRoute className={navItemClass}>
                            <Link to={"/user-management"} className={pathname === "/user-management" ? navLinkActiveClass : navLinkClass}>
                                <User size={17} /> {STRINGS.USERS}
                            </Link>
                        </GuardUserManagementRoute>

                        <GuardLogsRoute className={navItemClass}>
                            <Link to={"/system-logs"} className={pathname === "/system-logs" ? navLinkActiveClass : navLinkClass}>
                                <FileText size={17} /> {STRINGS.LOGS}
                            </Link>
                        </GuardLogsRoute>
                    </ul>
                </div>
            </nav>
        );
    }
}

export default withRouter(Sidebar);
