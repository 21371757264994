import React, { Component } from "react";
import { Button, DropdownItem, DropdownMenu, DropdownToggle, Form, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, UncontrolledDropdown } from "reactstrap";
import { ModalFormType } from "../../helpers/ModalFormType";
import Util from "../../helpers/Util";
import { GiftConfiguration } from "../../services/PureFlixAdmin/GiftVoucherService";

// Your component own properties
interface PropsType {
    showModalConfigName: boolean;
    selectedGift: GiftConfiguration | null;
    configList: GiftConfiguration[];
    toggleConfigNameModal: (show: boolean, type?: ModalFormType) => void;
    editGiftConfigName: (gift: GiftConfiguration) => Promise<void>;
}

interface State {
    disableForm: boolean;
    msg: string;
    gift: GiftConfiguration | null;
}

class GiftsEditConfigForm extends Component<PropsType, State, any> {
    private constructor(props: PropsType) {
        super(props);
        this.state = {
            msg: "",
            disableForm: false,
            gift: null,
        };
    }

    public componentDidUpdate(prevProps: PropsType): void {
        if (prevProps.selectedGift !== this.props.selectedGift) {
            this.setState({ gift: this.props.selectedGift });
        }
    }

    private toggleModalButton = (): void => {
        this.props.toggleConfigNameModal(!this.props.showModalConfigName);
    };

    private handleChange = (field: string, value: any): void => {
        this.setState((prevState) => ({ gift: { ...prevState.gift, [field]: value } }));
    };

    private saveConfig = (event: React.FormEvent<HTMLFormElement>): void => {
        try {
            event.preventDefault();
            this.setState({ disableForm: true });
            const payload = {
                ...this.state.gift,
            };
            Util.validateGiftObject(payload, this.props.configList);

            this.props.editGiftConfigName(payload);

            this.setState({ disableForm: false });
        } catch (err: unknown) {
            if (err instanceof Error) {
                this.setState({ msg: err.message, disableForm: false });
            } else {
                this.setState({ msg: "Unknown error in GiftsEditConfigForm.tsx:saveConfig", disableForm: false });
            }
        }
    };

    public render(): JSX.Element {
        const { showModalConfigName } = this.props;
        return (
            <>
                <Modal isOpen={showModalConfigName} toggle={this.toggleModalButton} size="lg">
                    <ModalHeader toggle={this.toggleModalButton}>Edit Gift </ModalHeader>
                    <Form id="category-edit-form" onSubmit={(event): void => this.saveConfig(event)}>
                        <fieldset disabled={this.state.disableForm}>
                            <ModalBody>
                                <div className="" style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", width: "100%" }}>
                                    <div className="form-group col-sm-12">
                                        <Label htmlFor="title" className="col-form-label">
                                            Title
                                        </Label>

                                        <Input
                                            type="text"
                                            name="title"
                                            className="form-control"
                                            id="title"
                                            aria-describedby="title"
                                            required
                                            onChange={(e) => this.handleChange("title", e.target.value)}
                                            defaultValue={this.state.gift?.title}
                                            readOnly
                                        />
                                    </div>
                                </div>

                                <div className="" style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", width: "100%" }}>
                                    <div className="form-group col-sm-12">
                                        <Label htmlFor="codeField" className=" col-form-label">
                                            Redeemable Product Full URL ID
                                        </Label>

                                        <Input
                                            type="text"
                                            name="redeemableProductId"
                                            className="form-control"
                                            id="redeemableProductId"
                                            aria-describedby="redeemableProductId"
                                            required
                                            onChange={(e) => this.handleChange("redeemableProductId", e.target.value)}
                                            defaultValue={this.state.gift?.redeemableProductId}
                                        />
                                    </div>
                                </div>
                                <div className="" style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", width: "100%" }}>
                                    {/* <div className="form-group col-sm-4">
                                        <Label htmlFor="type" className=" col-form-label">
                                            Gift Type
                                        </Label>

                                        <UncontrolledDropdown color="primary">
                                            <DropdownToggle caret>{this.state.gift?.type || "Select Type"}</DropdownToggle>
                                            <DropdownMenu>
                                                {giftTypes.map((gift) => (
                                                    <DropdownItem key={gift} onClick={(): void => this.handleChange("type", gift)}>
                                                        {gift}
                                                    </DropdownItem>
                                                ))}
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </div>
                                    <div className="form-group col-sm-4">
                                        <Label htmlFor="isPremiumUsersOnly" className="col-form-label">
                                            Is Premium Users Only?
                                        </Label>

                                        <UncontrolledDropdown color="primary">
                                            <DropdownToggle caret>{this.state.gift?.isPremiumUsersOnly ? "Premium Users Only" : "All Users" || "Select Scope"}</DropdownToggle>
                                            <DropdownMenu>
                                                {offerTypes.map((offer) => (
                                                    <DropdownItem key={offer.label} onClick={(): void => this.handleChange("isPremiumUsersOnly", offer.value)}>
                                                        {offer.label}
                                                    </DropdownItem>
                                                ))}
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </div> */}
                                    <div className="form-group col-sm-4">
                                        <Label htmlFor="codeField" className="col-form-label">
                                            Promotion is:
                                        </Label>

                                        <UncontrolledDropdown color="primary">
                                            <DropdownToggle caret color={this.state.gift?.active ? "primary" : "danger"}>
                                                {this.state.gift?.active ? "ON" : "OFF"}
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                <DropdownItem onClick={(): void => this.handleChange("active", true)}>ON</DropdownItem>
                                                <DropdownItem onClick={(): void => this.handleChange("active", false)}>OFF</DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </div>
                                    {/* </div>
                                <div className="" style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", width: "100%" }}> */}
                                    {/* <div className="form-group col-sm-4">
                                        <Label htmlFor="codeField" className=" col-form-label">
                                            Promotion ID - full url
                                        </Label>

                                        <Input
                                            type="text"
                                            name="promotionId"
                                            className="form-control"
                                            id="codeField"
                                            aria-describedby="codeHelp"
                                            required
                                            onChange={(e) => this.handleChange("promotionId", e.target.value)}
                                            defaultValue={this.state.gift?.promotionId}
                                        />
                                    </div> */}

                                    <div className="form-group col-sm-4">
                                        <Label htmlFor="codeField" className="col-form-label">
                                            Discount (0% to 100%)
                                        </Label>

                                        <Input
                                            type="number"
                                            name="discountOffered"
                                            className="form-control"
                                            id="codeField"
                                            aria-describedby="codeHelp"
                                            required
                                            onChange={(e) => this.handleChange("discountOffered", Number(e.target.value))}
                                            defaultValue={this.state.gift?.discountOffered}
                                            readOnly={this.state.gift?.type === "promotions" || !this.state.gift?.isPremiumUsersOnly}
                                        />
                                    </div>
                                </div>
                            </ModalBody>
                            <ModalFooter>
                                <span className={this.state.msg ? "alert alert-warning" : ""} role="alert">
                                    {this.state.msg}
                                </span>
                                <Button type="submit" className="btn btn-dark" color="secondary">
                                    Save Button
                                </Button>
                            </ModalFooter>
                        </fieldset>
                    </Form>
                </Modal>
            </>
        );
    }
}

export default GiftsEditConfigForm;
