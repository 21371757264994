import { AttributeType, GroupType, UserType } from "@aws-sdk/client-cognito-identity-provider";
import axios, { AxiosInstance } from "axios";
import { RoleEnum } from "../../config/permissions";
import Util from "../../helpers/Util";

export interface User extends Partial<UserType> {
    username?: string;
    role?: RoleEnum | undefined | string;
}
class UserService {
    private endpoint = "/users";

    private service: AxiosInstance;

    public constructor() {
        this.service = axios.create(Util.getAxiosConfig());
    }

    private formatUsername(username: string): string {
        if (username.includes("mpx/")) {
            return username.replace("mpx/", "");
        }
        // Replace spaces with underscores and remove any characters that don't match the pattern
        username = username.replace(/\s+/g, "_").replace(/[^\p{L}\p{M}\p{S}\p{N}\p{P}]/gu, "");

        // Ensure the username is not empty after formatting
        if (username.length === 0) {
            throw new Error("Username is invalid after formatting. Please use a valid username.");
        }

        return username;
    }

    private async changeUserGroup(username: string, newUserGroup: RoleEnum): Promise<RoleEnum> {
        // remove user from any previous groups.
        try {
            const email = this.formatUsername(username);
            const { data }: { data: GroupType[] } = await this.service.post(`${this.endpoint}/list-groups`, { username: email });

            for (const item of data) {
                await this.service.post(`${this.endpoint}/remove-group`, { username, groupName: item.GroupName });
            }

            // assign user to the desired group.
            await this.service.post(`${this.endpoint}/assign-group`, { username, groupName: newUserGroup });
            return newUserGroup;
        } catch (err) {
            throw Util.handleServiceError(err, Error().stack?.toString());
        }
    }

    private async getUserGroup(username: string): Promise<string> {
        try {
            const email = this.formatUsername(username);

            const { data } = await this.service.post(`${this.endpoint}/list-groups`, { username: email });

            return data?.map((group: any) => group.GroupName)[0] as string;
        } catch (err) {
            throw Util.handleServiceError(err, Error().stack?.toString());
        }
    }

    public async getAll(): Promise<{ username: string; role: string }[]> {
        try {
            const { data } = await this.service.get(`${this.endpoint}`);

            const userWithGroups = await Promise.all(
                data.map(async (user: any) => {
                    if (user.Username) {
                        return {
                            username: user?.Username as string,
                            role: await this.getUserGroup(user?.Username as string),
                        };
                    }
                })
            );

            return userWithGroups;
        } catch (error: unknown) {
            throw Util.handleServiceError(error, Error().stack?.toString());
        }
    }

    public async listUserNumberPerGroup(): Promise<Record<string, number>> {
        try {
            const users = await this.getAll();
            return users.reduce((acc: Record<string, number>, { role }: { username: string; role: string }) => {
                acc[role] = (acc[role] || 0) + 1;
                return acc;
            }, {});
        } catch (err: unknown) {
            throw Util.handleServiceError(err, Error().stack?.toString());
        }
    }
    public async getUser(username: string): Promise<{ username: string; role: string }> {
        try {
            const email = this.formatUsername(username);

            const { data } = await this.service.get(`${this.endpoint}/${email}`);

            return {
                username: data.Username as string,
                role: await this.getUserGroup(data.Username as string),
            };
        } catch (error: unknown) {
            throw Util.handleServiceError(error, Error().stack?.toString());
        }
    }
    public async searchUser(searchString: string): Promise<User[] | null> {
        try {
            if (searchString) {
                const { data } = await this.service.get(`${this.endpoint}/search/${searchString}`);

                const userWithGroups = await Promise.all(
                    data.map(async (user: any) => ({
                        username: user.Username as string,
                        role: await this.getUserGroup(user.Username as string),
                    }))
                );

                return userWithGroups;
            }
            return null;
        } catch (error: unknown) {
            throw Util.handleServiceError(error, Error().stack?.toString());
        }
    }

    public async createUser(payload: User): Promise<User | undefined> {
        try {
            const email = this.formatUsername(payload.username as string);

            // in the future add new attributes such as user details, name, age, etc... under userAttributes array.
            const { data: user } = await this.service.post(`${this.endpoint}`, { username: email, userAttributes: [{ Name: "email", Value: email }] });
            if (user) {
                await this.service.post(`${this.endpoint}/assign-group`, { username: email, groupName: payload.role });
            } else {
                throw new Error("User not created");
            }

            return { username: user.User?.Username, role: payload.role };
        } catch (error: unknown) {
            // console.log({ error });
            throw Util.handleServiceError(error, Error().stack?.toString());
        }
    }

    // Current username param for logging purposes
    public async updateUser(user: User & { currentUsername?: string }): Promise<User> {
        try {
            let updatedUsername = this.formatUsername(user.currentUsername as string);

            // update new username on currentUsername only if username is different from current one.
            if (user.username !== updatedUsername) {
                const attributes: AttributeType[] = [
                    { Name: "email", Value: user.username as string },
                    {
                        Name: "preferred_username",
                        Value: user.username as string,
                    },
                ];
                const { username }: User = await this.service.put(`${this.endpoint}/${updatedUsername}`, {
                    userAttributes: attributes,
                });
                updatedUsername = username as string;
            }
            // change user group/role;
            const newRole = await this.changeUserGroup(updatedUsername, user.role as string);
            return { username: updatedUsername, role: newRole };
        } catch (error: unknown) {
            throw Util.handleServiceError(error, Error().stack?.toString());
        }
    }

    // passing the username for logging purposes
    public async deleteUser(user: User): Promise<boolean> {
        try {
            const email = this.formatUsername(user.username as string);
            await this.service.delete(`${this.endpoint}/${email}`);
            return true;
        } catch (error: unknown) {
            throw Util.handleServiceError(error, Error().stack?.toString());
        }
    }
}

export default new UserService();
