import React from "react";
import { Alert, Button, Col, Form, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner, Table } from "reactstrap";
import { formatRoleName, RoleEnum } from "../../config/permissions";
import UserService, { User } from "../../services/PureFlixAdmin/UserService";
import Title from "../Title";
import UserModal from "./UserModal";

const STRINGS: Record<string, string> = {
    PAGE_TITLE: "Users",
    SEARCH: "Search",
    USERNAME: "User name",
    NO_USER: "No users were found",
    REMOVE_USER_MODAL_TITLE: "Removal Confirmation",
    CREATE_NEW: "Create",
    UPDATE_SUCCESS: "User updated Successfully",
    CREATE_SUCCESS: "User created Successfully",
    DELETE_CONFIRMATION: "Do you want to remove the selected user",
    DELETE_SUCCESS: "User deleted successfully",
};

interface State {
    isLoading: boolean;
    alertMessage?: string;
    input: string;
    users: User[] | null;
    selectedUser?: User;
    isUserModalVisible: boolean;
    isConfirmationModalVisible: boolean;
}
class AdminUsers extends React.Component<Record<string, any>, State> {
    private _isMounted;
    private constructor(props: Record<string, any>) {
        super(props);
        this.state = {
            isLoading: false,
            input: "",
            isUserModalVisible: false,
            isConfirmationModalVisible: false,
            users: null,
        };
        this._isMounted = false;
    }

    public componentDidMount(): void {
        this._isMounted = true;
        if (this._isMounted) {
            this.onSearch();
        }
    }

    public componentWillUnmount(): void {
        this._isMounted = false;
    }

    private onChangeInput = (event: React.ChangeEvent<HTMLInputElement>): void => {
        this.setState({ input: event.target.value });
    };

    private onSearch = async (event?: React.FormEvent | React.MouseEvent): Promise<any> => {
        const { input } = this.state;
        if (event) {
            event.preventDefault();
        }
        this.setState({ isLoading: true });
        // Callbacking this instead of async awaiting because react setState calls
        UserService.searchUser(input)
            .then((res) => {
                if (this._isMounted) {
                    this.setState({
                        users: res,
                        isLoading: false,
                    });
                }
            })
            .catch(() => {
                // it returns 404 if email not found, so we treat it like this
                this.setState({ isLoading: false, users: [] });
            });
        return false;
    };

    private onCreateNewUser = (): void => {
        this.setState({ selectedUser: undefined, isUserModalVisible: true });
    };

    private onEditUser = (user: User): void => {
        this.setState({ selectedUser: user, isUserModalVisible: true });
    };

    private onHideUserModal = (): void => {
        this.setState({
            isUserModalVisible: false,
        });
    };

    private saveUser = async (userData: User): Promise<void | undefined> => {
        if (this.state.selectedUser?.username) {
            await UserService.updateUser({ ...userData, currentUsername: this.state.selectedUser?.username });
            this.setState({ alertMessage: STRINGS.UPDATE_SUCCESS });
        } else {
            await UserService.createUser(userData);
            this.setState({ alertMessage: STRINGS.CREATE_SUCCESS });
        }
        this.onSearch();
    };

    private onConfirmRemoveUser = (user: User): void => {
        this.setState({
            selectedUser: user,
            isConfirmationModalVisible: true,
        });
    };

    private removeUser = async (user?: User): Promise<void> => {
        if (!user) {
            return;
        }

        await UserService.deleteUser(user);
        this.setState({ alertMessage: STRINGS.DELETE_SUCCESS, users: null });
        this.closeConfirmationModal();
        this.onSearch();
    };

    private closeConfirmationModal = (): void => {
        this.setState({ isConfirmationModalVisible: false, selectedUser: undefined });
    };

    private Form = (): JSX.Element => {
        const { input } = this.state;
        return (
            <Form id="gift-vouchers-form" style={{ textAlign: "left" }} onSubmit={this.onSearch}>
                <fieldset>
                    <Row>
                        <Col sm={1}>
                            <Label for="inputText">Username</Label>
                        </Col>
                        <Col sm={3}>
                            <Input type="text" name="inputText" id="inputText" onChange={this.onChangeInput} value={input} />
                        </Col>
                        <Col sm={1}>
                            <Button color="primary" onClick={this.onSearch}>
                                {STRINGS.SEARCH}
                            </Button>
                        </Col>
                        <Col sm={1}>
                            <Button color="primary" onClick={this.onCreateNewUser}>
                                {STRINGS.CREATE_NEW}
                            </Button>
                        </Col>
                    </Row>
                </fieldset>
            </Form>
        );
    };

    private UsersTable = (): JSX.Element => {
        const { users, isLoading } = this.state;

        if (isLoading) {
            return (
                <div>
                    <Spinner color="primary" />
                </div>
            );
        }
        if (!users) {
            return <></>;
        }

        if (users.length === 0) {
            return (
                <div>
                    <Alert color="primary">{STRINGS.NO_USER}</Alert>
                </div>
            );
        }

        const Header = (): JSX.Element => (
            <tr>
                <th>Username</th>
                <th>Role</th>
                <th>Actions</th>
            </tr>
        );

        const Body = (): JSX.Element => {
            return (
                <>
                    {users.map(
                        (user: User, index: number): JSX.Element => (
                            <tr key={index}>
                                <td>{user.username}</td>
                                <td>{formatRoleName(user.role as RoleEnum)}</td>
                                <td>
                                    <Button onClick={(): void => this.onEditUser(user)} className="btn" color="primary" style={{ marginRight: "5px" }}>
                                        Edit
                                    </Button>
                                    <Button onClick={(): void => this.onConfirmRemoveUser(user)} className="btn" color="danger">
                                        Remove
                                    </Button>
                                </td>
                            </tr>
                        )
                    )}
                </>
            );
        };

        return (
            <Table>
                <thead>
                    <Header />
                </thead>
                <tbody>
                    <Body />
                </tbody>
            </Table>
        );
    };

    private ConfirmationModal = (): JSX.Element => {
        const { selectedUser } = this.state;
        if (!selectedUser) {
            return <></>;
        }

        return (
            <Modal isOpen={true} fade={false} size="sm" toggle={this.closeConfirmationModal}>
                <ModalHeader>{STRINGS.REMOVE_USER_MODAL_TITLE}</ModalHeader>
                <ModalBody>
                    {STRINGS.DELETE_CONFIRMATION} <strong>{selectedUser.username}</strong>?
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={(): Promise<void> => this.removeUser(selectedUser)}>
                        Confirm
                    </Button>
                    <Button color="secondary" onClick={this.closeConfirmationModal}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        );
    };

    public render(): JSX.Element {
        const { selectedUser, isUserModalVisible, isConfirmationModalVisible, alertMessage } = this.state;
        return (
            <div className="csr-tool-page">
                <Title content={STRINGS.PAGE_TITLE} />

                <this.Form />
                <div>
                    {alertMessage && (
                        <Alert
                            color="success"
                            toggle={(): void => {
                                this.setState({ alertMessage: undefined });
                            }}
                        >
                            {alertMessage}
                        </Alert>
                    )}
                </div>
                <div style={{ marginTop: "15px" }}>
                    <this.UsersTable />
                </div>
                {isUserModalVisible && <UserModal selectedUser={selectedUser || {}} onSaveUser={this.saveUser} onHide={this.onHideUserModal} />}
                {isConfirmationModalVisible && <this.ConfirmationModal />}
            </div>
        );
    }
}

export default AdminUsers;
