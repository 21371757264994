import axios, { AxiosInstance } from "axios";
import Util from "../../helpers/Util";

export type GiftType = "promotions" | "vouchers" | null | any;
export interface GiftConfiguration {
    createdAt: number;
    type: GiftType;
    promotionId: string;
    title: string;
    redeemableProductId: string;
    purchasableProductId: string;
    price: number | string;
    numberOfMonths: number | string;
    isPremiumUsersOnly: boolean;
    discountOffered: number | string;
    active: boolean;
    updatedAt: number;
}

export interface GiftVoucher {
    promotionId: string;
    giftVoucherInfo: {
        currency: string;
        activationDate: number; // date
        purchasePriceIncludingTax: number;
        purchaseDate: number;
        purchasingUserId: string;
        redeemingUserId: string;
        redemptionDate: number;
        revoked?: boolean;
    };
    userIds: string[];
    promotionTitle: string;
    promotionDescription: string;
    code: string;
    promotionCodeId: string;
}

export interface PromotionEntry {
    id: string;
    guid: string;
    updated: number;
    title: string;
    description: string;
    added: number;
    locked: boolean;
    promotionConditionId: string;
    promotionActionId: string;
    availableDate: number;
    expirationDate: number;
    active: boolean;
    priority: number;
    useLimit: number;
    scopeTarget: string;
    subscriptionDiscountEndDate: number;
}

export interface VouchersResponse {
    startIndex: number;
    itemsPerPage: number;
    entryCount: number;
    entries: PromotionEntry[];
}

class GiftVoucherService {
    private endpoint = "/giftvouchers";

    private service: AxiosInstance;

    public constructor() {
        this.service = axios.create(Util.getAxiosConfig());
    }

    public async getGiftVouchersByEmail(userEmail: string, findBy: string): Promise<GiftVoucher[]> {
        try {
            const res = await this.service.get(`${this.endpoint}?email=${userEmail}&findBy=${findBy}`);
            return res.data;
        } catch (error: unknown) {
            throw Util.handleServiceError(error, Error().stack?.toString());
        }
    }

    public async getAll(): Promise<GiftConfiguration[]> {
        try {
            const res = await this.service.get(`${this.endpoint}/all`);
            return res.data;
        } catch (error: unknown) {
            throw Util.handleServiceError(error, Error().stack?.toString());
        }
    }

    public async getVouchersFromMPX(): Promise<PromotionEntry[]> {
        try {
            const config = {
                params: {
                    isActive: true,
                },
            };

            const responses = await this.service.get(`${this.endpoint}/mpx`, config);
            return responses.data.entries;
        } catch (error: unknown) {
            throw Util.handleServiceError(error, Error().stack?.toString());
        }
    }
    public async update(payload: GiftConfiguration[]): Promise<boolean> {
        try {
            await this.service.put(this.endpoint, payload);
            return true;
        } catch (error: unknown) {
            throw Util.handleServiceError(error, Error().stack?.toString());
        }
    }
}

export default new GiftVoucherService();
