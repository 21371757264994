import axios, { AxiosRequestConfig } from "axios";
import dayjs from "dayjs";
import { GiftConfiguration } from "../services/PureFlixAdmin/GiftVoucherService";

class Util {
    public static getAPIBase(): string {
        const currentUrl = window.location.href;
        if (currentUrl.indexOf("localhost") !== -1) {
            // Development
            return `http://localhost${process.env.REACT_APP_API_BASE}`;
        }
        return process.env.REACT_APP_API_BASE || "/api/v1";
    }

    public static getAxiosConfig(): AxiosRequestConfig {
        return {
            baseURL: Util.getAPIBase(),
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("auth-token")}`,
            },
        };
    }

    public static unixTimeInSeconds(unixTime: number): number {
        if (unixTime.toString().length < 13) {
            return unixTime;
        }
        return Math.round(unixTime / 1000);
    }

    public static unixTimeToFormattedDate(unixTime: number, format = ""): string {
        return dayjs.unix(Util.unixTimeInSeconds(unixTime)).format(format);
    }

    public static timestampToUTCString(timestamp: number): string {
        return new Date(timestamp).toUTCString();
    }

    public static secondsToHHMMSS(seconds: string | number = 0): string {
        let remainingSeconds = parseInt(seconds as string, 10);

        const hours = Math.floor(remainingSeconds / 3600);
        remainingSeconds = remainingSeconds % 3600;

        const minutes = Math.floor(remainingSeconds / 60);
        remainingSeconds = remainingSeconds % 60;

        const hh = String(hours).padStart(2, "0");
        const mm = String(minutes).padStart(2, "0");
        const ss = String(remainingSeconds).padStart(2, "0");

        return `${hh}:${mm}:${ss}`;
    }

    /**
     * Sorts alphabetically an array of objects by some specific key.
     * @param {String} property Key of the object to sort.
     */
    public static dynamicSort(property: string): any {
        let sortOrder = 1;

        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }

        return function (a: any, b: any): any {
            if (sortOrder === -1) {
                return b[property].localeCompare(a[property]);
            } else {
                return a[property].localeCompare(b[property]);
            }
        };
    }

    public static validateInput(input: string): string {
        const trimmedInput = input.trim();
        if (/\s/.test(trimmedInput)) {
            throw new Error("White spaces found");
        }
        return input;
    }

    public static checkPromotionInputField = (value: string): string => {
        if (!value) return "";
        let id = "";
        if (value.includes("/")) {
            id = value.split("/").pop();
        } else {
            id = value;
        }
        return id;
    };

    public static validateGiftObject(input: Partial<GiftConfiguration>, giftList?: GiftConfiguration[]): boolean {
        if (giftList?.length) {
            const match = giftList.filter((listItem) => this.cleanEnvString(listItem.promotionId) === this.cleanEnvString(input.promotionId as string));

            if (match.length > 1) {
                throw new Error("Cannot add/edit a duplicate item. Please use unique promotion id values for each gift.");
            }
        }
        if (input.type === "promotions" && Boolean(input.discountOffered > 0)) {
            // Allow only vouchers to offer discounts.
            throw new Error(`Promotions cannot offer a discount. Please set discount offered value to zero.`);
        }
        if (!input.redeemableProductId?.includes("http://")) {
            throw new Error(`Product Id should be the complete URL`);
        }
        if (input.purchasableProductId && !input.purchasableProductId?.includes("http://")) {
            throw new Error(`Product Id should be the complete URL`);
        }

        for (const key in input) {
            if (!(key in input)) {
                throw new Error(`Configuration missing attribute: ${key}`);
            }

            // New validation checks for each property
            switch (key) {
                case "type":
                    if (!input.type) {
                        throw new Error("Type cannot be null or empty.");
                    }
                    break;
                case "promotionId":
                    if (typeof input.promotionId !== "string" || input.promotionId.trim() === "") {
                        throw new Error("Promotion ID must be a non-empty string.");
                    }
                    break;
                case "title":
                    if (typeof input.title !== "string" || input.title.trim() === "") {
                        throw new Error("Title must be a non-empty string.");
                    }
                    break;
                case "redeemableProductId":
                    if (typeof input.redeemableProductId !== "string" || input.redeemableProductId.trim() === "") {
                        throw new Error("Redeemable Product ID must be a non-empty string.");
                    }
                    break;
                case "discountOffered":
                    if (typeof input.discountOffered !== "number" && input.type === "vouchers") {
                        throw new Error("Discount offered must be a non-negative number.");
                    } else if (input.type === "vouchers" && input.discountOffered === 0 && input.isPremiumUsersOnly) {
                        throw new Error("Premium Users should have a discount percentage on vouchers.");
                    } else if (input.type === "promotions" && input.discountOffered > 0) {
                        throw new Error("Promotions should not have a discount value. Please set to zero.");
                    } else if (input.discountOffered < 1 && input.type === "vouchers" && input.isPremiumUsersOnly) {
                        throw new Error("Discounts percentage should be a number from 1 to 100. (i.e. 1% to 100%)");
                    }
                    break;
                case "isPremiumUsersOnly":
                    if (typeof input.isPremiumUsersOnly !== "boolean") {
                        throw new Error("isPremiumUsersOnly must be a boolean.");
                    }
                    break;
                case "active":
                    if (typeof input.active !== "boolean") {
                        throw new Error("Active must be a boolean.");
                    }
                    break;
            }
        }

        return true; // Return true if all validations pass
    }

    public static checkDuplicates(names: string[], nameToCheck: string): void {
        names.forEach((name) => {
            if (name === nameToCheck) {
                throw new Error(`${name} already exists`);
            }
        });
    }

    public static handleServiceError(error: unknown, callStack: unknown): Error {
        if (axios.isAxiosError(error)) {
            if (error.response) {
                return new Error(error.response.data.message);
            } else if (error.request) {
                return new Error(`Request error: ${error.message}`);
            } else {
                return new Error(`Request error without error.response or error.request in ${error} : ${callStack}`);
            }
        } else if (error instanceof Error) {
            return new Error(`${error.name}: ${error.message}`);
        } else {
            return new Error(`Unknown error: ${JSON.stringify(error)}`);
        }
    }

    public static cleanEnvString(envValue: string): string {
        return String(envValue?.replace(/"/g, "")?.trim()); // Clean the account value
    }

    public static getOwnerId(): string {
        if (this.cleanEnvString(process.env.NODE_ENV) !== "production") {
            return this.cleanEnvString(process.env.REACT_APP_MPX_OWNER_ACCOUNT_DEVZ as string);
        }
        return this.cleanEnvString(process.env.REACT_APP_MPX_OWNER_ACCOUNT_PRDZ as string);
    }

    public static generateTimestampId(): string {
        return Date.now().toString(); // Returns the current timestamp in milliseconds as a string
    }

    public static friendlyTimestampFormat(timestamp: number): string {
        if (!timestamp) {
            return "-";
        }
        const date = new Date(timestamp);

        const options = {
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            timeZoneName: "short",
        };

        return date.toLocaleString("en-US", options);
    }
}

export default Util;
