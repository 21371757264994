import React, { Component } from "react";
import { Button, DropdownItem, DropdownMenu, DropdownToggle, Form, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, UncontrolledDropdown } from "reactstrap";
import Util from "../../helpers/Util";
import { GiftConfiguration } from "../../services/PureFlixAdmin/GiftVoucherService";
import PromotionService from "../../services/PureFlixAdmin/PromotionService";

interface PropsType {
    showModalForm: boolean;
    toggleModalAddConfig: () => void;
    saveConfig: (config: GiftConfiguration) => void;
    giftsList: GiftConfiguration[];
}
interface State {
    disableForm: boolean;
    msg: string;
    gift: GiftConfiguration | null;
    found: boolean;
}

class GiftsAddConfigForm extends Component<PropsType, State> {
    public state: State = {
        msg: "",
        disableForm: false,
        gift: null,
        found: false,
    };

    // public componentDidUpdate(prevProps: PropsType) {
    //     // Check if the selectedGiftConfig prop has changed
    //     if (prevProps.selectedGiftConfig !== this.props.selectedGiftConfig) {
    //         this.setState({ gift: this.props.selectedGiftConfig });
    //     }
    // }

    private toggleModalItem = (): void => {
        this.setState({
            pageCode: "",
            gift: null,
            msg: "",
            found: false,
        });
        this.props.toggleModalAddConfig();
    };

    public componentDidMount(): void {
        this.setState({ gift: null, found: false });
    }
    private verifyGiftTitle = (title: string) => {
        // Updated to use regex for matching "voucher"
        const isVoucher = /voucher/i.test(title);
        const isPromotion = /promotion/i.test(title);
        const isPremiumUserOnly = /premium/i.test(title);
        const monthsMatch = title.match(/(\d+)\s+month/i);
        const annualMatch = title.match(/(\d+)\s+Year\s+Annual/i);

        const numberOfMonths = monthsMatch ? parseInt(monthsMatch[1]) : annualMatch ? parseInt(annualMatch[1]) * 12 : 0; // Convert years to months

        if (isVoucher) {
            return { type: "vouchers", isPremiumUserOnly, numberOfMonths };
        }
        if (isPromotion) {
            return { type: "promotions", isPremiumUserOnly, numberOfMonths };
        }
        if (isVoucher && isPromotion) {
            return { type: "gift cannot be voucher and promotion at the same time", isPremiumUserOnly, numberOfMonths: 0 };
        }
        return { type: "not a voucher, nor a promotion", isPremiumUserOnly, numberOfMonths: 0 };
    };

    private handleSearchGift = async (e: any) => {
        try {
            e.preventDefault();
            const { giftsList } = this.props;
            const promotionId = this.state.gift?.promotionId;
            giftsList.find((listItem) => {
                const existingItem = Util.checkPromotionInputField(listItem.promotionId) as string;
                const newItem = Util.checkPromotionInputField(promotionId as string);
                if (existingItem == newItem) {
                    throw new Error("Cannot add/edit a duplicate item. Please use unique promotion id values for each gift.");
                }
            });

            if (!promotionId) return;
            const id = Util.checkPromotionInputField(promotionId);
            const data = await PromotionService.fetchPromotionDetails(id as string);
            const giftType = this.verifyGiftTitle(data?.promotion.title).type;
            const isPromo = giftType === "promotions";
            const purchasableProductId = isPromo ? null : data.product.id;
            const voucherPrice = Number(data?.product?.pricingPlan?.pricingTiers[0]?.amounts?.USD) * 100;

            const giftPrice = isPromo ? "-" : voucherPrice;
            const discount = isPromo ? "-" : 0;
            this.setState({
                gift: {
                    createdAt: data.promotion.added,
                    type: giftType,
                    promotionId: data.promotion.id,
                    title: data.promotion.title,
                    purchasableProductId: purchasableProductId,
                    redeemableProductId: null,
                    isPremiumUsersOnly: this.verifyGiftTitle(data.promotion.title).isPremiumUserOnly,
                    price: giftPrice,
                    discountOffered: discount,
                    numberOfMonths: this.verifyGiftTitle(data.promotion.title).numberOfMonths,
                    active: data.promotion.active,
                },
                found: true,
            });
        } catch (err: unknown) {
            if (err instanceof Error) {
                this.setState({ msg: err.message, disableForm: false });
            } else {
                this.setState({ msg: "Unknown error in GiftsEditConfigForm.tsx:saveConfig", disableForm: false });
            }
        }
    };

    private handleChange = (field: string, value: any): void => {
        this.setState((prevState: any) => ({
            ...prevState,
            gift: { ...prevState.gift, [field]: value },
        }));
    };

    private saveConfig = async (event?: React.FormEvent<HTMLFormElement>): Promise<void> => {
        // Prevent form submission if there is no search result

        if (!this.state.gift?.redeemableProductId) {
            this.setState({ msg: "Before saving, please add a redeemable MPX product to which this gift is linked to." });
            return; // Exit the function early
        }

        try {
            event?.preventDefault();
            this.setState({ disableForm: true, msg: "" });

            Util.validateGiftObject(this.state.gift, this.props.giftsList);
            const payload = {
                ...this.state.gift,
            };
            await this.props.saveConfig(payload as GiftConfiguration);

            this.setState({
                disableForm: false,
                pageCode: "",
            });
        } catch (err: unknown) {
            if (err instanceof Error) {
                this.setState({ msg: err.message, disableForm: false });
            } else {
                this.setState({ msg: "Unknown error in AddConfigFormGift:saveConfig", disableForm: false });
            }
        }
    };

    private handleSubmitAction = (e: any, searchResult: boolean) => {
        searchResult ? this.saveConfig(e) : this.handleSearchGift(e);
    };

    public render(): JSX.Element {
        const searchResult = Boolean(this.state.found);

        return (
            <>
                <Modal isOpen={this.props.showModalForm} toggle={this.toggleModalItem} size="lg">
                    <ModalHeader toggle={this.toggleModalItem}>Add New Gift Configuration</ModalHeader>
                    <Form id="category-edit-form" onSubmit={(e) => this.handleSubmitAction(e, searchResult)}>
                        <fieldset disabled={this.state.disableForm}>
                            <ModalBody>
                                {!searchResult ? (
                                    <div
                                        className=""
                                        style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", width: "100%", marginTop: 20 }}
                                    >
                                        <div className="form-group col-sm-12">
                                            <Label htmlFor="promotionId" className="col-form-label">
                                                <strong> Promotion URL ID </strong>
                                            </Label>

                                            <Input
                                                type="text"
                                                name="promotionId"
                                                // className="form-control"
                                                id="promotionId"
                                                aria-describedby="promotionId"
                                                required
                                                onChange={(e) => this.handleChange("promotionId", e.target.value)}
                                                value={this.state.gift?.promotionId || ""}
                                            />
                                        </div>
                                    </div>
                                ) : (
                                    <>
                                        <div className="" style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", width: "100%" }}>
                                            <Label htmlFor="title" className="col-form-label">
                                                <strong>Please Verify the info below:</strong>
                                            </Label>
                                        </div>
                                        <div className="" style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", width: "100%" }}>
                                            <div className="form-group col-sm-12">
                                                <Label htmlFor="title" className="col-form-label">
                                                    <strong>Title</strong>
                                                </Label>

                                                <Input
                                                    type="text"
                                                    name="title"
                                                    className="form-control"
                                                    id="title"
                                                    aria-describedby="title"
                                                    plaintext
                                                    onChange={(e) => this.handleChange("title", e.target.value)}
                                                    value={this.state.gift?.title}
                                                    readOnly
                                                />
                                            </div>
                                        </div>
                                        <div className="" style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", width: "100%" }}>
                                            <div className="form-group col-sm-12">
                                                <Label htmlFor="redeemableProductId" className="col-form-label">
                                                    <strong>Redeemable Product URL Id</strong>
                                                </Label>

                                                <Input
                                                    type="text"
                                                    name="redeemableProductId"
                                                    className="form-control"
                                                    id="redeemableProductId"
                                                    aria-describedby="redeemableProductId"
                                                    onChange={(e) => this.handleChange("redeemableProductId", e.target.value)}
                                                    value={this.state.gift?.redeemableProductId}
                                                />
                                            </div>
                                        </div>
                                        <div className="" style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", width: "100%" }}>
                                            <div className=" col-sm-3">
                                                <Label htmlFor="type" className=" ">
                                                    <strong> Gift Type</strong>
                                                </Label>

                                                <Input
                                                    type="text"
                                                    name="type"
                                                    className="form-control"
                                                    id="type"
                                                    aria-describedby="type"
                                                    required
                                                    onChange={(e) => this.handleChange("type", e.target.value)}
                                                    defaultValue={this.state.gift?.type}
                                                    readOnly
                                                />
                                            </div>
                                            <div className=" col-sm-3">
                                                <Label htmlFor="isPremiumUsersOnly" className=" col-form-label">
                                                    <strong> Is Premium Users? </strong>
                                                </Label>
                                                <Input
                                                    type="text"
                                                    name="isPremiumUsersOnly"
                                                    className="form-control"
                                                    id="isPremiumUsersOnly"
                                                    aria-describedby="isPremiumUsersOnly"
                                                    required
                                                    onChange={(e) => this.handleChange("isPremiumUsersOnly", e.target.value)}
                                                    defaultValue={this.state.gift?.isPremiumUsersOnly}
                                                    readOnly
                                                />
                                            </div>
                                            <div className=" col-sm-3">
                                                <Label htmlFor="active" className="col-form-label">
                                                    <strong> Status </strong>
                                                </Label>
                                                <UncontrolledDropdown color="primary">
                                                    <DropdownToggle caret color={this.state.gift?.active ? "primary" : "danger"}>
                                                        {this.state.gift?.active ? "Published" : "Hidden"}
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        <DropdownItem onClick={(): void => this.handleChange("active", true)}>Published</DropdownItem>
                                                        <DropdownItem onClick={(): void => this.handleChange("active", false)}>Hidden</DropdownItem>
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                            </div>
                                        </div>

                                        <div className="" style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", width: "100%" }}>
                                            <div className="form-group col-sm-12">
                                                <Label htmlFor="purchasableProductId" className=" col-form-label">
                                                    <strong> Purchasable Product URL ID </strong>
                                                </Label>

                                                <Input
                                                    type="text"
                                                    name="purchasableProductId"
                                                    className="form-control"
                                                    id="purchasableProductId"
                                                    aria-describedby="purchasableProductId"
                                                    required
                                                    onChange={(e) => this.handleChange("purchasableProductId", e.target.value)}
                                                    defaultValue={this.state.gift?.purchasableProductId}
                                                    readOnly={this.state.gift?.type === "vouchers"}
                                                />
                                            </div>
                                        </div>
                                        <div className="" style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", width: "100%" }}>
                                            <div className="form-group col-sm-4">
                                                <Label htmlFor="discountOffered" className="col-form-label">
                                                    <strong> Discount (0% to 100%) </strong>
                                                </Label>

                                                <Input
                                                    type="text"
                                                    name="discountOffered"
                                                    className="form-control"
                                                    id="discountOffered"
                                                    aria-describedby="discountOffered"
                                                    onChange={(e) => this.handleChange("discountOffered", Number(e.target.value))}
                                                    defaultValue={this.state.gift?.discountOffered}
                                                    readOnly={this.state.gift?.type === "promotions" || !this.state.gift?.isPremiumUsersOnly}
                                                />
                                            </div>
                                            <div className="form-group col-sm-4">
                                                <Label htmlFor="numberOfMonths" className="col-form-label">
                                                    <strong> Number of Months </strong>
                                                </Label>

                                                <Input
                                                    type="number"
                                                    name="numberOfMonths"
                                                    className="form-control"
                                                    id="numberOfMonths"
                                                    aria-describedby="numberOfMonths"
                                                    onChange={(e) => this.handleChange("numberOfMonths", Number(e.target.value))}
                                                    defaultValue={this.state.gift?.numberOfMonths}
                                                    readOnly
                                                />
                                            </div>
                                            <div className="form-group col-sm-4">
                                                <Label htmlFor="price" className="col-form-label">
                                                    <strong> Price in cents </strong>
                                                </Label>

                                                <Input
                                                    type="number"
                                                    name="price"
                                                    className="form-control"
                                                    id="price"
                                                    aria-describedby="price"
                                                    onChange={(e) => this.handleChange("price", e.target.value)}
                                                    value={Number(this.state?.gift?.price) / 100}
                                                    readOnly
                                                />
                                            </div>
                                        </div>
                                    </>
                                )}
                                {/* </div> */}
                            </ModalBody>
                            {searchResult ? (
                                <ModalFooter>
                                    <span className={this.state.msg ? "alert alert-warning" : ""} role="alert">
                                        {this.state.msg}
                                    </span>
                                    <Button type="button" className="btn btn-dark" onClick={(e) => this.saveConfig(e)}>
                                        Save
                                    </Button>
                                </ModalFooter>
                            ) : (
                                <ModalFooter>
                                    <span className={this.state.msg ? "alert alert-warning" : ""} role="alert">
                                        {this.state.msg}
                                    </span>
                                    <Button type="submit" className="btn btn-dark" color="secondary" onClick={(e) => this.handleSearchGift(e)}>
                                        Search Gift
                                    </Button>
                                </ModalFooter>
                            )}
                        </fieldset>
                    </Form>
                </Modal>
            </>
        );
    }
}

export default GiftsAddConfigForm;
