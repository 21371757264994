import React from "react";
import { Button, DropdownItem, DropdownMenu, DropdownToggle, Form, Label, Modal, ModalBody, ModalFooter, ModalHeader, Spinner, UncontrolledDropdown } from "reactstrap";
import PromotionService, { Promotion } from "../../services/PureFlixAdmin/PromotionService";

interface Props {
    showModal: boolean;
    applyPromotionToContract: (promotion: Promotion) => void;
    setModalVisibility: (show: boolean) => void;
}

interface State {
    availablePromotions: Promotion[];
    selectedPromotion: Promotion;
    isLoading: boolean;
}

export default class PromotionModal extends React.PureComponent<Props, State> {
    public state = {
        isLoading: true,
        selectedPromotion: {} as any as Promotion,
        availablePromotions: [],
    };

    public componentDidMount(): void {
        this.initPromotionList();
    }

    private initPromotionList = async (): Promise<void> => {
        let availablePromotions = undefined;
        try {
            availablePromotions = await PromotionService.getActivePromotions();
        } catch (error: unknown) {
            if (error instanceof Error) {
                // console.log(error.message);
                throw error;
            } else {
                // console.log("Unexpected error: ", error);
                throw new Error("Unexpected error in initPromotionList.");
            }
        }
        if (availablePromotions) {
            this.setState({ availablePromotions, isLoading: false });
        }
    };

    private onFormSubmit = (): void => {
        const { applyPromotionToContract } = this.props;
        const { selectedPromotion } = this.state;

        applyPromotionToContract(selectedPromotion);
        this.onToggleModal();
    };

    private onToggleModal = (): void => {
        this.props.setModalVisibility(!this.props.showModal);
    };

    public render(): JSX.Element {
        const { showModal } = this.props;
        const { selectedPromotion, availablePromotions, isLoading } = this.state;
        return (
            <Modal isOpen={showModal} size="lg">
                <ModalHeader toggle={this.onToggleModal}>Add Promotion to Contract</ModalHeader>
                <Form id="category-edit-form" className="cubo-form">
                    <ModalBody>
                        <div className="form-group row">
                            <Label htmlFor="promotionField" className="col-sm-2 col-form-label">
                                Promotion
                            </Label>
                            <div className="col-sm-10">
                                {!isLoading ? (
                                    <UncontrolledDropdown color="primary">
                                        <DropdownToggle caret>{selectedPromotion?.title || "Select Promotion"}</DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem header>Select Promotion</DropdownItem>
                                            {availablePromotions.map((promo: Promotion) => (
                                                <DropdownItem key={promo.id} onClick={(): void => this.setState({ selectedPromotion: promo })}>
                                                    {promo.title}
                                                </DropdownItem>
                                            ))}
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                ) : (
                                    <Spinner color="primary" />
                                )}
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={this.onFormSubmit} className="btn btn-dark" color="secondary" disabled={!selectedPromotion}>
                            Save
                        </Button>
                    </ModalFooter>
                </Form>
            </Modal>
        );
    }
}
