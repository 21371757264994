import React from "react";
import { ExternalLink } from "react-feather";
import { Spinner } from "reactstrap";
import { PromotionEntry } from "../../services/PureFlixAdmin/GiftVoucherService";
import Util from "../../helpers/Util";

interface Props {
    selectedGiftIdForPreview: string;
    isPreviewLoading: boolean;
    mpxPromotionsList: PromotionEntry[];
}

const styles = {
    container: {
        fontFamily: "Arial, sans-serif",
        padding: "20px",
        maxWidth: "800px",
        margin: "0 auto",
    },
    heading: {
        textAlign: "center" as const,
        color: "#333",
        marginBottom: "20px",
    },
    card: {
        backgroundColor: "#f0f0f0",
        borderRadius: "8px",
        boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)",
        padding: "20px",
        marginBottom: "20px",
    },
    fieldGroup: {
        display: "flex",
        flexWrap: "wrap" as const,
        marginBottom: "10px",
    },
    field: {
        flex: "1 1 50%",
        marginBottom: "10px",
    },
    label: {
        fontWeight: "bold",
        marginRight: "8px",
        color: "#4a90e2",
    },
    value: {
        color: "#333",
    },
};
class GiftsPreviewPanel extends React.Component<Props> {
    private openMembershipGiftFunnel(): void {
        window.open(`${process.env.REACT_APP_MEMBERSHIP_PORTAL_URL}/gift/welcome`);
    }

    public render(): JSX.Element | null {
        const [promotion] = this.props.mpxPromotionsList;

        const giftPreview = [{ ...promotion, numId: promotion?.id.split("/").pop() }];

        if (this.props.isPreviewLoading) {
            return <Spinner color="primary" id="preview-spinner" />;
        }
        return (
            <div style={styles.container}>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <p className="preview-title" style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                            <h4>
                                <strong>{giftPreview[0].title}</strong>
                            </h4>

                            <ExternalLink className="preview-hubspot-page" style={{ marginLeft: 10, marginBottom: 10 }} onClick={(): void => this.openMembershipGiftFunnel()} />
                        </p>
                        <h6 className="preview-title" style={{ marginBottom: 18 }}>
                            <strong>{this.props.selectedGiftIdForPreview}</strong>
                        </h6>
                    </div>
                </div>

                {giftPreview.map((item, index) => (
                    <div key={index} style={styles.card}>
                        <div style={styles.fieldGroup}>
                            <div style={styles.field}>
                                <span style={styles.label}>Promo Id Num:</span>
                                <span style={styles.value}>{item.numId}</span>
                            </div>
                            <div style={styles.field}>
                                <span style={styles.label}>Title:</span>
                                <span style={styles.value}>
                                    <strong>{item.title}</strong>
                                </span>
                            </div>
                        </div>
                        <div style={styles.fieldGroup}>
                            <div style={styles.field}>
                                <span style={styles.label}>Id:</span>
                                <span style={styles.value}>{item.id}</span>
                            </div>
                            <div style={styles.field}>
                                <span style={styles.label}>GUID:</span>
                                <span style={styles.value}>{item.guid}</span>
                            </div>
                        </div>
                        <div style={styles.fieldGroup}>
                            <div style={styles.field}>
                                <span style={styles.label}>Active in MPX:</span>
                                <span style={styles.value}>{item.active ? "Yes" : "No"}</span>
                            </div>
                            <div style={styles.field}>
                                <span style={styles.label}>Added:</span>
                                <span style={styles.value}>{Util.friendlyTimestampFormat(item.added)}</span>
                            </div>
                        </div>
                        <div style={styles.fieldGroup}>
                            <div style={styles.field}>
                                <span style={styles.label}>Available Date:</span>
                                <span style={styles.value}>{Util.friendlyTimestampFormat(item.availableDate)}</span>
                            </div>
                            <div style={styles.field}>
                                <span style={styles.label}>Description:</span>
                                <span style={styles.value}>{item.description}</span>
                            </div>
                        </div>
                        <div style={styles.fieldGroup}>
                            <div style={styles.field}>
                                <span style={styles.label}>Expiration Date:</span>
                                <span style={styles.value}>{Util.friendlyTimestampFormat(item.expirationDate)}</span>
                            </div>
                            <div style={styles.field}>
                                <span style={styles.label}>Locked:</span>
                                <span style={styles.value}>{item.locked ? "Yes" : "No"}</span>
                            </div>
                        </div>
                        <div style={styles.fieldGroup}>
                            <div style={styles.field}>
                                <span style={styles.label}>Priority:</span>
                                <span style={styles.value}>{item.priority}</span>
                            </div>
                            <div style={styles.field}>
                                <span style={styles.label}>Promotion Action Id:</span>
                                <span style={styles.value}>{item.promotionActionId}</span>
                            </div>
                        </div>
                        <div style={styles.fieldGroup}>
                            <div style={styles.field}>
                                <span style={styles.label}>Promotion Condition Id:</span>
                                <span style={styles.value}>{item.promotionConditionId}</span>
                            </div>
                            <div style={styles.field}>
                                <span style={styles.label}>Scope Target:</span>
                                <span style={styles.value}>{item.scopeTarget}</span>
                            </div>
                        </div>
                        <div style={styles.fieldGroup}>
                            <div style={styles.field}>
                                <span style={styles.label}>Subscription Discount End Date:</span>
                                <span style={styles.value}>{item.subscriptionDiscountEndDate}</span>
                            </div>
                            <div style={styles.field}>
                                <span style={styles.label}>Updated:</span>
                                <span style={styles.value}>{Util.friendlyTimestampFormat(item.updated)}</span>
                            </div>
                        </div>
                        <div style={styles.fieldGroup}>
                            <div style={styles.field}>
                                <span style={styles.label}>Use Limit:</span>
                                <span style={styles.value}>{item.useLimit}</span>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        );
    }
}

export default GiftsPreviewPanel;
