import React, { Component } from "react";
import { Edit, Eye, Trash } from "react-feather";

import { GiftVoucherEnum } from "../../config/permissions";
import { ModalFormType } from "../../helpers/ModalFormType";
import { withPermission } from "../../hoc/withPermission";
import { GiftConfiguration } from "../../services/PureFlixAdmin/GiftVoucherService";
import GiftsEditConfigForm from "./GiftsEditConfigForm";
import Util from "../../helpers/Util";

interface Props {
    configList: GiftConfiguration[];
    openConfirmationModal: (config: any, selectedPromotionId?: string, indexConfig?: number) => void;
    updateGift: (gift: GiftConfiguration, modalFormType: string) => void;
    editGiftConfigName: (value: GiftConfiguration) => void;
    loadPreview: (gift: GiftConfiguration) => Promise<void>;
}

interface State {
    showModalButton: boolean;
    showModalConfigName: boolean;
    modalFormType: ModalFormType;
    selectedGift: GiftConfiguration | null;
}

const Div = (props) => <div {...props} />;

const GuardActionButton = withPermission(Div, GiftVoucherEnum.CONFIGURE_GIFT);
const GuardEditButton = withPermission(Div, GiftVoucherEnum.VIEW_GIFT);
const GuardDeleteButton = withPermission(Div, GiftVoucherEnum.DELETE_GIFT);

class GiftsConfigTable extends Component<Props, State> {
    public state = {
        showModalButton: false,
        showModalConfigName: false,
        modalFormType: ModalFormType.ADD,
        selectedGift: null,
    };

    public toggleButtonModal = (show: boolean): void => {
        this.setState({
            showModalButton: show,
        });
    };

    public setSelectedGift = (type: ModalFormType, selectedGift: GiftConfiguration): void => {
        if (type) {
            this.setState({
                modalFormType: type,
            });
        }

        if (selectedGift.promotionId) {
            this.setState({ selectedGift });
        }
    };

    public toggleConfigNameModal = (show: boolean, type?: ModalFormType): void => {
        if (type) {
            this.setState({
                showModalConfigName: show,
                modalFormType: type,
            });
        }
        this.setState({
            showModalConfigName: show,
        });
    };

    // private updateGift = async (newGift: GiftConfiguration, modalFormType: string): Promise<void> => {
    //     this.setState({ showModalButton: false });
    //     await this.props.updateGift(newGift, modalFormType);
    // };

    private editGiftConfigName = async (gift: GiftConfiguration): Promise<void> => {
        this.setState({ showModalConfigName: false });
        await this.props.editGiftConfigName(gift);
    };
    private getIdFromUrlId(guid: string) {
        if (!guid) return "-";
        return guid?.split("/").pop();
    }

    public render(): JSX.Element {
        const gifts = this.props.configList;

        // const sortByTitle = gifts.sort((a: GiftConfiguration, b: GiftConfiguration) => a?.title - b?.title);
        const sortByUpdatedDate = gifts.sort((a: GiftConfiguration, b: GiftConfiguration) => b?.updatedAt - a?.updatedAt);
        return (
            <div style={{ marginBottom: "5%", marginTop: 20 }}>
                {sortByUpdatedDate.map((gift, index) => (
                    <div key={index} style={{ border: "3px solid #d0d0d0", margin: 20, borderRadius: 10, padding: 10 }}>
                        <div style={{ display: "flex", flexDirection: "row", marginTop: 0, marginBottom: 10 }}>
                            <td scope="col" style={{ width: "100%", display: "flex", flexDirection: "row", fontWeight: 600, marginTop: 7 }}>
                                <div style={{ width: "100%", fontWeight: 600, fontSize: 22 }}>{gift?.title}</div>
                            </td>
                            <div
                                style={{
                                    backgroundColor: gift?.active ? "green" : "red",
                                    color: gift?.active ? "#c3ff87" : "white",
                                    fontWeight: 700,
                                    fontSize: 18,
                                    padding: 10,
                                    borderRadius: 10,
                                    opacity: 0.9,
                                }}
                            >
                                {gift?.active ? "ON" : "OFF"}
                            </div>
                        </div>
                        <table className="table table-striped table-bordered">
                            <thead className="thead-light" style={{ position: "initial" }}>
                                <tr>
                                    <th scope="col">Type</th>
                                    <th scope="col">Is Premium</th>
                                    <th scope="col">Purchase Prod. Id</th>
                                    <th scope="col">Redeem Prod. Id</th>
                                    <th scope="col">Promotion Id</th>
                                    <th scope="col">Discount (%)</th>
                                    <th scope="col">Price </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td scope="col">{gift?.type}</td>
                                    <td scope="col">{gift?.isPremiumUsersOnly ? "Yes" : "No"}</td>
                                    <td scope="col">{this.getIdFromUrlId(gift?.purchasableProductId)}</td>
                                    <td scope="col">{this.getIdFromUrlId(gift?.redeemableProductId)}</td>
                                    <td scope="col">{this.getIdFromUrlId(gift?.promotionId)}</td>
                                    <td scope="col">{typeof gift?.discountOffered === "number" ? gift?.discountOffered + "%" : "-"}</td>
                                    <td scope="col">
                                        {typeof gift.price === "number" ? new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(gift?.price / 100) : "-"}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <tr style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                            <div style={{ width: "100%", padding: 4 }}>added at: {Util.friendlyTimestampFormat(Number(gift?.createdAt))}</div>
                            <div style={{ width: "100%", padding: 4 }}>last configured: {Util.friendlyTimestampFormat(Number(gift?.updatedAt))}</div>
                        </tr>
                        <tr style={{ display: "flex", flexDirection: "row", width: "100%", alignItems: "center", justifyContent: "center", cursor: "pointer" }}>
                            <GuardActionButton
                                style={{
                                    cursor: "pointer",
                                    borderRadius: 7,
                                    margin: 7,
                                    width: "100%",
                                    display: "flex",
                                    justifyItems: "space-between",
                                    alignItems: "center",
                                    flexDirection: "row",
                                    backgroundColor: "#f6f6f6",
                                    border: "4px solid green",
                                    padding: 3,
                                }}
                                onClick={(): void => {
                                    this.props.loadPreview(gift);
                                }}
                            >
                                <Eye style={{ cursor: "pointer", marginRight: 10 }} />

                                <div style={{ display: "flex", width: "100%", color: "green" }}>View</div>
                            </GuardActionButton>

                            <GuardEditButton
                                style={{
                                    cursor: "pointer",
                                    borderRadius: 7,
                                    margin: 5,
                                    backgroundColor: "#f6f6f6",

                                    border: "4px solid orange",
                                    color: "orange",

                                    width: "100%",
                                    display: "flex",
                                    padding: 3,
                                }}
                                onClick={(): void => {
                                    this.toggleConfigNameModal(!this.state.showModalConfigName);
                                    this.setSelectedGift(ModalFormType.UPDATE, gift);
                                }}
                            >
                                <Edit style={{ cursor: "pointer", marginRight: 10 }} />
                                <span style={{ display: "flex", width: "100%" }}>Edit</span>
                            </GuardEditButton>
                            <GuardDeleteButton
                                style={{
                                    cursor: "pointer",
                                    borderRadius: 7,
                                    margin: 5,
                                    width: "100%",
                                    display: "flex",

                                    backgroundColor: "#f6f6f6",
                                    border: "4px solid red",
                                    color: "red",
                                    padding: 3,
                                }}
                                onClick={(): void => {
                                    this.props.openConfirmationModal(gift, gift.promotionId, index);
                                }}
                            >
                                <Trash style={{ cursor: "pointer", marginRight: 10 }} />
                                <span style={{ display: "flex", width: "100%" }}>Delete</span>
                            </GuardDeleteButton>
                        </tr>
                    </div>
                ))}

                <GiftsEditConfigForm
                    configList={this.props.configList}
                    showModalConfigName={this.state.showModalConfigName}
                    toggleConfigNameModal={this.toggleConfigNameModal}
                    editGiftConfigName={this.editGiftConfigName}
                    selectedGift={this.state.selectedGift}
                ></GiftsEditConfigForm>
            </div>
        );
    }
}

export default GiftsConfigTable;
