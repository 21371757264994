import { PureComponent } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Button, Col, Container, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner, UncontrolledAlert } from "reactstrap";
import { AvailableRoutesEnum, GiftVoucherEnum } from "../../config/permissions";
import AuthHelper from "../../helpers/AuthHelper";
import { ModalFormType } from "../../helpers/ModalFormType";
import { withPermission } from "../../hoc/withPermission";
import Title from "../Title";
import GiftsAddConfigForm from "./GiftsAddConfigForm";
import GiftVoucherService, { GiftConfiguration, PromotionEntry } from "../../services/PureFlixAdmin/GiftVoucherService";
import GiftsConfigTable from "./GiftsConfigTable";
import GiftsPreviewPanel from "./GiftsPreviewPanel";

type AlertType = "danger" | "warning" | "success";

type PropsType = RouteComponentProps;

interface State {
    alertMessage: string;
    alertType: AlertType;
    isConfigListLoading: boolean;
    isPreviewLoading: boolean;
    configList: GiftConfiguration[];
    selectedGiftConfig: GiftConfiguration;
    selectedConfigGiftPromoId: string;
    mpxPromotionsList: PromotionEntry[];
    showModalForm: boolean;
    modalConfirmation: boolean;
    showModalButton: boolean;
    modalFormType: ModalFormType;
    indexButton: number;
    indexConfig: number;
    selectedGiftIdForPreview: string;
    isPreviewModalOpen: boolean;
    searchResult: any;
}

enum STRINGS {
    PAGE_TITLE = "Gifts",
}

const GuardAddGiftButton = withPermission(Button, GiftVoucherEnum.ADD_GIFT);

class Gifts extends PureComponent<PropsType, State> {
    private _isMounted;

    public constructor(props: PropsType) {
        super(props);
        this.state = {
            alertMessage: "",
            alertType: "danger",
            isConfigListLoading: true,
            isPreviewLoading: true,
            configList: [],
            selectedConfigGiftPromoId: "",
            selectedGiftConfig: {
                numberOfMonths: 0,
                createdAt: "",
                type: null,
                promotionId: "",
                title: "",
                redeemableProductId: "",
                price: 0,
                isPremiumUsersOnly: false,
                discountOffered: 0,
                active: false,
            },
            selectedGiftIdForPreview: "",
            mpxPromotionsList: [],
            modalConfirmation: false,
            showModalForm: false,
            showModalButton: false,
            modalFormType: ModalFormType.ADD,
            indexButton: 0,
            indexConfig: 0,
            isPreviewModalOpen: false,
        };
        this._isMounted = false;
    }

    public componentDidMount(): void {
        this._isMounted = true;
        this.getAll();
    }
    public componentWillUnmount(): void {
        this._isMounted = false;
    }

    private checkLoggedIn = async (): Promise<boolean> => {
        try {
            await AuthHelper.checkLoggedIn();
            return true;
        } catch (err: unknown) {
            if (err instanceof Error) {
                this.props.history.push("/", { message: err.message });
            } else {
                this.props.history.push("/", { message: "Unknown error in Gifts.tsx:checkLoggedIn" });
            }
            return false;
        }
    };

    private async getAll(): Promise<void> {
        try {
            const isLoggedIn = await this.checkLoggedIn();
            if (isLoggedIn && this._isMounted) {
                this.setState({ isConfigListLoading: true, isPreviewLoading: true });
                const configList = await GiftVoucherService.getAll();
                if (this._isMounted) {
                    this.setState({
                        isConfigListLoading: false,
                        configList,
                    });
                }
            }
        } catch (err) {
            this.setError(new Error("There was an error while fetching the Gifts configuration"));
        }
    }

    private getVouchersFromMPX = async (gift: GiftConfiguration): Promise<void> => {
        this.setState({
            alertMessage: "",
            isPreviewLoading: true,
            mpxPromotionsList: [],
            isPreviewModalOpen: true,
        });

        const mpxPromotionsList = await GiftVoucherService.getVouchersFromMPX(gift.active);

        const promotionFound = mpxPromotionsList.filter((item) => item.id === gift.promotionId);
        if (this._isMounted) {
            this.setState({
                isPreviewLoading: false,
                mpxPromotionsList: promotionFound,
                selectedGiftIdForPreview: gift.promotionId,
            });
        }
    };

    private setError = (err: any, alertType: AlertType = "danger"): void => {
        const alertMessage = err.response ? err.response.data.message : err.message;
        this.setAlert(alertMessage, alertType);
    };

    private setAlert(message = "", type: AlertType = "danger"): void {
        this.setState({
            alertMessage: message,
            alertType: type,
        });
    }

    private renderAlert(): JSX.Element {
        return (
            <UncontrolledAlert color={this.state.alertType} className="alert">
                {this.state.alertMessage}
            </UncontrolledAlert>
        );
    }

    private onConfirmDelete = (): void => {
        const configList = [...this.state.configList];
        const configListIndex = configList.findIndex((gift) => gift.promotionId === this.state.selectedGiftConfig.promotionId);

        this.deleteHandler(configList, configListIndex);
        this.closeConfirmationModal();
    };

    private deleteHandler = async (configList: GiftConfiguration[], configListIndex: number): Promise<void> => {
        configList = configList.filter((_, index) => index !== configListIndex);
        await this.updateConfigList(configList);
    };

    private openConfirmationModal = (config: GiftConfiguration, indexButton?: number, indexConfig?: number): void => {
        if (indexButton) {
            this.setState({ indexButton });
        }

        if (indexConfig) {
            this.setState({ indexConfig });
        }

        this.setState({
            modalConfirmation: !this.state.modalConfirmation,
            alertMessage: "",
            selectedGiftConfig: config,
        });
    };

    private closeConfirmationModal = (): void => {
        this.setState({
            modalConfirmation: !this.state.modalConfirmation,
            alertMessage: "",
        });
    };

    private updateGifts = (newGift: GiftConfiguration, modalFormType: string): void => {
        const { configList } = this.state;

        if (modalFormType === ModalFormType.ADD) {
            configList.push(newGift);
        } else {
            const existingItems = configList.filter((gift) => gift.promotionId === newGift.promotionId);
            if (existingItems.length) {
                const indexToUpdate = configList.findIndex((gift) => gift.promotionId === existingItems[0].promotionId);
                if (indexToUpdate !== -1) {
                    configList[indexToUpdate] = newGift; // Replace the existing item with newGift
                }
            }
        }
        this.updateConfigList(configList);
    };

    private async updateConfigList(configList: GiftConfiguration[]): Promise<void> {
        this.setState({ isConfigListLoading: true });
        await GiftVoucherService.update(configList);
        this.setState({
            configList,
            isConfigListLoading: false,
        });
    }

    private saveConfig = (giftConfig: GiftConfiguration): void => {
        this.setState({
            showModalForm: !this.state.showModalForm,
        });
        const { configList } = this.state;
        configList.push({ ...giftConfig, updatedAt: new Date().getTime() });
        this.updateConfigList(configList);
    };

    private editGiftConfigName = (newGiftVersion: GiftConfiguration): void => {
        const { configList } = this.state;
        const giftListWithoutOldGiftVersion = configList.filter((el) => el.createdAt !== newGiftVersion.createdAt);
        const newList = [{ ...newGiftVersion, updatedAt: new Date().getTime() }, ...giftListWithoutOldGiftVersion];
        this.updateConfigList(newList);
    };

    private toggleModalAddConfig = (): void => {
        this.setState((prevState) => ({
            alertMessage: "",
            modalFormType: ModalFormType.ADD,
            showModalForm: !prevState.showModalForm,
            searchResult: null,
        }));
    };

    private togglePreviewModal = (): void => {
        this.setState({
            isPreviewModalOpen: !this.state.isPreviewModalOpen,
        });
    };

    public render(): JSX.Element {
        const { alertMessage, isConfigListLoading } = this.state;

        return (
            <div className="plans-page container-fluid">
                <Title content={STRINGS.PAGE_TITLE} />

                <Row>
                    <Col>
                        <GuardAddGiftButton color="primary" className="add-config" onClick={this.toggleModalAddConfig}>
                            Add New Gift
                        </GuardAddGiftButton>
                        {alertMessage && this.renderAlert()}
                    </Col>
                </Row>
                <Row>
                    {!this.state.alertMessage ? (
                        <Container fluid>
                            <Row>
                                <Col>
                                    {isConfigListLoading ? (
                                        <Spinner color="primary" />
                                    ) : (
                                        <>
                                            <GiftsConfigTable
                                                configList={this.state.configList}
                                                loadPreview={this.getVouchersFromMPX}
                                                openConfirmationModal={this.openConfirmationModal}
                                                updateGifts={this.updateGifts}
                                                editGiftConfigName={this.editGiftConfigName}
                                            />
                                        </>
                                    )}
                                </Col>
                            </Row>
                        </Container>
                    ) : null}
                </Row>
                <Modal isOpen={this.state.isPreviewModalOpen} toggle={this.togglePreviewModal} size="xl">
                    <ModalHeader toggle={this.togglePreviewModal}>Filter Preview</ModalHeader>
                    <ModalBody>
                        <GiftsPreviewPanel
                            isPreviewLoading={this.state.isPreviewLoading}
                            mpxPromotionsList={this.state.mpxPromotionsList}
                            selectedGiftIdForPreview={this.state.selectedGiftIdForPreview}
                        />
                    </ModalBody>
                </Modal>
                <Modal isOpen={this.state.modalConfirmation} toggle={this.closeConfirmationModal}>
                    <ModalHeader toggle={this.closeConfirmationModal}>Confirmation</ModalHeader>
                    <ModalBody>Would you like to delete this Gift?</ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.onConfirmDelete}>
                            Yes
                        </Button>{" "}
                        <Button color="secondary" onClick={this.closeConfirmationModal}>
                            No
                        </Button>
                    </ModalFooter>
                </Modal>
                <GiftsAddConfigForm
                    showModalForm={this.state.showModalForm}
                    toggleModalAddConfig={this.toggleModalAddConfig}
                    saveConfig={this.saveConfig}
                    giftsList={this.state.configList}
                    selectedGiftConfig={this.state.selectedGiftConfig}
                    handleSetSearchResult={this.handleSetSearchResult}
                />
            </div>
        );
    }
}

export default withRouter(withPermission(Gifts, AvailableRoutesEnum.GIFTS));
