import React, { Component } from "react";
import { Edit, Eye, Trash2 } from "react-feather";
import { Button, Col, Row } from "reactstrap";
import { PlansEnum } from "../../config/permissions";
import { ModalFormType } from "../../helpers/ModalFormType";
import { withPermission } from "../../hoc/withPermission";
import { Buttons, PlansConfig } from "../../services/PureFlixAdmin/PlansService";
import PlansButtonForm from "./PlansButtonForm";
import PlansEditConfigForm from "./PlansEditConfigForm";

interface Props {
    configList: PlansConfig[];
    config: PlansConfig;
    indexConfig: number;
    loadPreview: (pageCode: string, button: Buttons) => Promise<void>;
    openConfirmationModal: (config: any, indexButton?: number, indexConfig?: number) => void;
    updatePlans: (button: Buttons, indexPlan: number, indexButton: number, modalFormType: string) => void;
    editPlanConfigName: (config: string, indexConfig: number) => void;
}

interface State {
    showModalButton: boolean;
    showModalConfigName: boolean;
    modalFormType: ModalFormType;
    indexButton: number;
    button: Buttons;
}
const Th = (props) => <th {...props} />;
const GuardAddButton = withPermission(Button, PlansEnum.CONFIGURE_PLAN);
const GuardViewButton = withPermission(Th, PlansEnum.DELETE_PLAN);
const GuardEditButton = withPermission(Th, PlansEnum.VIEW_PLAN);
const GuardDeleteButton = withPermission(Th, PlansEnum.DELETE_PLAN);
const GuardEditIcon = withPermission(Edit, PlansEnum.DELETE_PLAN);
const GuardDeleteIcon = withPermission(Trash2, PlansEnum.DELETE_PLAN);

class PlansConfigTable extends Component<Props, State> {
    public state = {
        showModalButton: false,
        showModalConfigName: false,
        modalFormType: ModalFormType.ADD,
        indexButton: 0,
        button: {
            buttonCode: "",
            filters: "",
        },
    };

    public toggleButtonModal = (show: boolean): void => {
        this.setState({
            showModalButton: show,
        });
    };

    public setButtonModal = (type: ModalFormType, indexButton: number, button: Buttons): void => {
        if (type) {
            this.setState({
                modalFormType: type,
            });
        }

        if (indexButton || button) {
            this.setState({ indexButton, button });
        }
    };

    public toggleConfigNameModal = (show: boolean, type?: ModalFormType): void => {
        if (type) {
            this.setState({
                showModalConfigName: show,
                modalFormType: type,
            });
        }
        this.setState({
            showModalConfigName: show,
        });
    };

    private updatePlans = async (config: Buttons, indexPlan: number, indexButton: number, modalFormType: string): Promise<void> => {
        this.setState({ showModalButton: false });
        await this.props.updatePlans(config, indexPlan, indexButton, modalFormType);
    };

    private editPlanConfigName = async (config: string, indexConfig: number): Promise<void> => {
        this.setState({ showModalConfigName: false });
        await this.props.editPlanConfigName(config, indexConfig);
    };

    public render(): JSX.Element {
        return this.props.config ? (
            <div>
                <div className="page-code">
                    <span style={{ fontWeight: "bold" }}>Page code: </span>
                    {this.props.config.pageCode}
                    {this.props.config.pageCode !== "default" ? (
                        <GuardEditIcon className="action-icon" size={17} onClick={(): void => this.toggleConfigNameModal(!this.state.showModalConfigName, ModalFormType.UPDATE)} />
                    ) : null}
                    {this.props.config.pageCode !== "default" ? (
                        <GuardDeleteIcon
                            className="action-icon"
                            size={17}
                            onClick={(): void => {
                                this.props.openConfirmationModal(this.props.config, undefined, this.props.indexConfig);
                            }}
                        />
                    ) : null}
                </div>
                <div>
                    <GuardAddButton
                        style={{ marginTop: "1rem" }}
                        color="primary"
                        className="add-config"
                        onClick={(): void => {
                            this.toggleButtonModal(!this.state.showModalButton);
                            this.setButtonModal(ModalFormType.ADD, 0, {
                                buttonCode: "",
                                filters: "",
                            });
                        }}
                    >
                        + Add Plan
                    </GuardAddButton>
                </div>
                <div>
                    <Row style={{ display: "block", marginBottom: "5%" }}>
                        <Col>
                            <table className="table table-striped table-bordered">
                                <thead className="thead-light">
                                    <tr>
                                        <th scope="col">Plan</th>
                                        <th scope="col">Filters</th>
                                        <GuardViewButton scope="col">Preview</GuardViewButton>
                                        <GuardEditButton scope="col">Edit</GuardEditButton>
                                        <GuardDeleteButton scope="col">Delete</GuardDeleteButton>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.props.config.buttons &&
                                        this.props.config.buttons.map((button, index): JSX.Element => {
                                            return (
                                                <React.Fragment key={index}>
                                                    <tr>
                                                        <td>{button.buttonCode}</td>
                                                        <td>{button.filters}</td>
                                                        <GuardViewButton className="action-button">
                                                            <Eye
                                                                className="action-icon"
                                                                size={17}
                                                                onClick={(): void => {
                                                                    this.props.loadPreview(this.props.config.pageCode, button);
                                                                }}
                                                            />
                                                        </GuardViewButton>
                                                        <GuardEditButton className="action-button">
                                                            <Edit
                                                                className="action-icon"
                                                                size={17}
                                                                onClick={(): void => {
                                                                    this.toggleButtonModal(!this.state.showModalButton);
                                                                    this.setButtonModal(ModalFormType.UPDATE, index, button);
                                                                }}
                                                            />
                                                        </GuardEditButton>
                                                        <GuardDeleteButton className="action-button">
                                                            {this.props.config.pageCode === "default" ? (
                                                                <Trash2 size={17} />
                                                            ) : (
                                                                <GuardDeleteButton
                                                                    className="action-icon"
                                                                    size={17}
                                                                    onClick={(): void => {
                                                                        this.props.openConfirmationModal(this.props.config, index);
                                                                    }}
                                                                />
                                                            )}
                                                        </GuardDeleteButton>
                                                    </tr>
                                                    <PlansEditConfigForm
                                                        indexConfig={this.props.indexConfig}
                                                        config={this.props.config}
                                                        configList={this.props.configList}
                                                        showModalConfigName={this.state.showModalConfigName}
                                                        toggleConfigNameModal={this.toggleConfigNameModal}
                                                        editPlanConfigName={this.editPlanConfigName}
                                                    ></PlansEditConfigForm>
                                                </React.Fragment>
                                            );
                                        })}
                                    <PlansButtonForm
                                        indexConfig={this.props.indexConfig}
                                        indexButton={this.state.indexButton}
                                        showModalButton={this.state.showModalButton}
                                        toggleButtonModal={this.toggleButtonModal}
                                        config={this.props.config}
                                        updatePlans={this.updatePlans}
                                        modalFormType={this.state.modalFormType}
                                        button={this.state.button}
                                    />
                                </tbody>
                            </table>
                        </Col>
                    </Row>
                </div>
            </div>
        ) : (
            <div>Loading config from Hubspot...</div>
        );
    }
}

export default PlansConfigTable;
